<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">学员管理</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">学员列表</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start; ">
          <div class="searchbox" style="margin-bottom:10px">
            <div
              title="学员姓名"
              style="display: flex; align-items: center"
              class="searchboxItem ci-full"
            >
              <span class="itemLabel">学员姓名:</span>
              <el-input
                v-model="userName"
                type="text"
                size="small"
                placeholder="学员姓名"
                clearable
              />
            </div>
            <div
              title="学员身份证号"
              style="display: flex; align-items: center"
              class="searchboxItem ci-full"
            >
              <span class="itemLabel" style="min-width:7rem"
                >学员身份证号:</span
              >
              <el-input
                v-model="idcard"
                type="text"
                size="small"
                placeholder="学员身份证号"
                clearable
              />
            </div>
            <div title="培训地区" class="searchboxItem ci-full">
              <span class="itemLabel" >培训地区:</span>
              <el-cascader
                :options="areatreeList"
                clearable
                filterable
                :props="propsarea"
                size="small"
                v-model="areaId"
              ></el-cascader>
            </div>
             <div title="是否有效" class="searchboxItem ci-full">
              <span class="itemLabel" >是否有效:</span>
              <el-select
                clearable
                size="small"
                v-model="validState"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in validStateType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            
          </div>
          <div class="searchbox" style="margin-bottom:10px">
           
            <div title="认证状态" class="searchboxItem ci-full">
              <span class="itemLabel" >认证状态:</span>
              <el-select
                clearable
                size="small"
                v-model="authentication"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in authenticationType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div title="学习状态" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:7rem">学习状态:</span>
              <el-select
                clearable
                size="small"
                v-model="studyState"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in studyStatusList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div title="操作人" class="searchboxItem ci-full">
              <span class="itemLabel" >操作人:</span>
              <el-input
                v-model="createMan"
                type="text"
                size="small"
                placeholder="操作人"
                clearable
              />
            </div>
            <div title="学员类型" class="searchboxItem ci-full">
              <span class="itemLabel" >学员类型:</span>
              <el-select
                  clearable
                  size="small"
                  v-model="userType"
                  placeholder="请选择"
              >
                <el-option
                    v-for="item in userTypeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div class="searchbox" style="margin-bottom:10px">
            <div title="培训类型" class="searchboxItem ci-full">
              <span class="itemLabel" >培训类型:</span>
              <tree
                ref="tree"
                @eventBtn="childBack"
                :ruleForm="ruleForm"
                size="small"
                @clearParams="clearParams"
                modal
                typeStu
              />
            </div>
            <div title="帐号创建人" class="searchboxItem ci-full">
          <span class="itemLabel" style="min-width:7rem">帐号创建人:</span>
          <el-select
            size="small"
            v-model="createId"
            remote
            :remote-method="getCompSourceList"
            filterable
            clearable
            placeholder="请输入帐号创建人"
          >
            <el-option
              v-for="item in fullNameList"
              :key="item.adminId"
              :label="item.fullname"
              :value="item.adminId"
            ></el-option>
          </el-select>
        </div>
            <div title="导入时间" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:7rem">导入时间:</span>
              <el-date-picker
                v-model="exportDate"
                size="small"
                type="daterange"
                style="width:5rem"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              />
            </div>
           
          </div>
          <div class="searchbox" >
            <div title="入班时间" class="searchboxItem ci-full">
              <span class="itemLabel" style="width:90px">入班时间:</span>
              <el-date-picker
                v-model="theClassTime"
                size="small"
                type="daterange"
                style="width:5rem"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              />
            </div>
           
            <div class="btnBox" >
              <el-button
                class="bgc-bv"
                round
                @click="getData()"
                >查询</el-button
              >
              <el-button class="bgc-bv" round @click="handleCreate('add')"
                >新增</el-button
              >
              <el-button class="bgc-bv" round @click="handleExport()"
                >批量导入</el-button
              >
              <el-button class="bgc-bv" round @click="handleExportList()"
                >导出</el-button
              >
            </div>
          </div>
        </div>
        <div>
          <span style="padding:8px 10px">导入人次:{{ importNum || 0 }}人</span>
          <span style="padding:8px 10px"
            >入班人次:{{ inTheClassNum || 0 }}人</span
          >
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
                @sort-change="changeSort"
          :default-sort="{prop: 'theClassTime', order: 'ascending'}"
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                width="80px"
                :index="indexMethod"
              />
              <el-table-column
                label="姓名"
                align="left"
                prop="userName"
                show-overflow-tooltip
                min-width="100"
              />
              <el-table-column
                label="身份证号"
                align="left"
                prop="idcard"
                show-overflow-tooltip
                min-width="170"
              />
              <el-table-column
                  label="性别"
                  align="left"
                  prop="sex"
                  show-overflow-tooltip
                  min-width="60"
              >
                <template slot-scope="scope">
                  {{ $setDictionary("SEX", scope.row.sex) }}
                </template>
              </el-table-column>
              <el-table-column
                  label="学员类型"
                  align="left"
                  prop="userType"
                  show-overflow-tooltip
                  min-width="120"
              >
                <template slot-scope="scope">
                  {{ $setDictionary("USERTYPE_SX", scope.row.userType) }}
                </template>
              </el-table-column>
              <el-table-column
                label="培训地区"
                align="left"
                prop="areaName"
                show-overflow-tooltip
                min-width="100"
              />
              <el-table-column
                label="培训类型"
                align="left"
                prop="trainTypeName"
                show-overflow-tooltip
                min-width="150"
              />
              <el-table-column
                label="行业类型"
                align="center"
                show-overflow-tooltip
                prop="industryName"
                min-width="100"
                v-if="trainType == '1' || trainTypegs == '2'"
              />
              <el-table-column
                label="岗位类型"
                align="center"
                show-overflow-tooltip
                prop="postName"
                min-width="100"
                v-if="trainType == '1'"
              />
              <el-table-column
                label="职业/工种"
                align="center"
                show-overflow-tooltip
                prop="occupationName"
                min-width="100"
                v-if="
                  trainTypegs == '2' ||
                    trainTypezyjn == '4' ||
                    trainTypejzpx == '17'
                "
              />
              <el-table-column
                label="培训等级"
                align="left"
                prop="trainLevelName"
                show-overflow-tooltip
                min-width="100"
                v-if="
                  trainType == '1' ||
                    trainTypezyjn == '4' ||
                    trainTypejzpx == '17'
                "
              />

              <el-table-column
                label="培训劵名称"
                align="left"
                prop="shanxiCouponName"
                show-overflow-tooltip
                min-width="180"
              />
              <el-table-column
                label="培训劵编号"
                align="left"
                prop="shanxiCouponCode"
                show-overflow-tooltip
                min-width="150"
              />
              <el-table-column
                label="导入时间"
                align="left"
                prop="createTime"
                show-overflow-tooltip
                min-width="100"
                :sort-orders="['ascending', 'descending']"
                sortable="custom"
              >
                <template slot-scope="scope">
                  {{ scope.row.createTime | momentDate }}
                </template>
              </el-table-column>
              <el-table-column
                label="帐号创建人"
                align="left"
                prop="adminName"
                show-overflow-tooltip
                min-width="160"
              />
              <el-table-column
                label="操作人"
                align="left"
                prop="createMan"
                show-overflow-tooltip
                min-width="160"
              />
              <el-table-column
                label="认证状态"
                align="left"
                prop="authentication"
                show-overflow-tooltip
                min-width="100"
              >
                <template slot-scope="scope">
                  {{
                    $setDictionary("AUTHENTICATION", scope.row.authentication)
                  }}
                </template>
              </el-table-column>
              <el-table-column
                label="入班时间"
                align="left"
                prop="theClassTime"
                  :sort-orders="['ascending', 'descending']"
                sortable="custom"
                show-overflow-tooltip
                min-width="100"
              />
              <el-table-column
                label="学习状态"
                align="left"
                prop="studyState"
                show-overflow-tooltip
                min-width="100"
              >
                <template slot-scope="scope">
                  {{ $setDictionary("STUDY_STATUS", scope.row.studyState) }}
                </template>
              </el-table-column>
               <el-table-column
                label="必修进度"
                align="left"
                prop="lessonNumPercentage"
                show-overflow-tooltip
                    :sort-by="'lessonNumPercentage'"
                     sortable="custom"
                min-width="100"
              >
                <template slot-scope="scope">
                  {{
                    scope.row.lessonNumPercentage == "0%"
                      ? ""
                      : scope.row.lessonNumPercentage
                  }}
                </template>
              </el-table-column>
              <el-table-column
                label="选修进度"
                align="left"
                prop="electiveLessonNumPercentage"
                    :sort-by="'electiveLessonNumPercentage'"
                    sortable="custom"
                show-overflow-tooltip
                min-width="100"
              >
               <template slot-scope="scope">
                  {{
                    scope.row.electiveLessonNumPercentage == "0%"
                      ? ""
                      : scope.row.electiveLessonNumPercentage
                  }}
                </template>
              </el-table-column>
              <el-table-column
                label="是否有效"
                align="left"
                prop="validState"
                show-overflow-tooltip
                min-width="100"
              >
                <template slot-scope="scope">
                  {{ scope.row.validState ? "是" : "否" }}
                </template>
              </el-table-column>
              <el-table-column
                label="备注"
                align="left"
                prop="remark"
                show-overflow-tooltip
                min-width="200"
              />
              <el-table-column
                label="操作"
                align="center"
                width="140px"
                fixed="right"
              >
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    type="text"
                    style="padding:0px 5px"
                    size="mini"
                    @click="handleCreate('edit', scope.row.agencyStudentId, scope.row.projectId)"
                    >编辑</el-button
                  >
                  <el-button
                    type="text"
                    style="padding:0px 5px"
                    size="mini"
                    @click="handleDelete(scope.row.agencyStudentId)"
                    >删除</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
        <el-dialog
          :title="dialogTitle"
          :visible.sync="dialogVisible"
          width="55%"
          top="3%"
          :center="true"
          :before-close="doClose"
          :close-on-click-modal="false"
        >
          <div class="ovy-a">
             <div style="display:flex;align-items: center;padding-left: 0;background: #f3f3f3;color:red;margin: 1rem 0;"> 
                <img src="../../assets/icon3.png" alt="">
                   <span  style="margin:1rem .25rem"
                >请注意，学员加入到与您选择的培训区域匹配的班级，才算入班成功。</span
              >
              </div>
            <el-form
              label-width="80px"
              ref="Form"
              :model="Form"
              :rules="rules"
            >
              <el-form-item label="学员姓名" prop="userName">
                <el-input
                  v-model="Form.userName"
                  type="text"
                  size="small"
                  placeholder="请输入学员姓名"
                  :disabled="Form.authentication == '20'"
                />
              </el-form-item>
              <el-form-item label="身份证号" prop="idcard">
                <el-input
                  v-model="Form.idcard"
                  type="text"
                  size="small"
                  placeholder="请输入身份证号"
                  :disabled="Form.authentication == '20'"
                />
              </el-form-item>
              <el-form-item label="培训区域" prop="areaId">
                <el-cascader
                  :options="areaList"
                  clearable
                  filterable
                  :props="propsarea"
                  size="small"
                  v-model="Form.areaId"
                ></el-cascader>
              </el-form-item>
              <el-form-item label="学员类型" prop="userType">
                <el-select v-model="Form.userType" placeholder="请选择" size="small">
                  <el-option
                      v-for="item in userTypeList1"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </div>
          <span
            slot="footer"
            class="dialog-footer"
            style="display: flex; justify-content: center;"
          >
            <el-button class="bgc-bv" @click="doClose('Form')">取消</el-button>
            <el-button class="bgc-bv" @click="getSure('Form')">确定</el-button>
          </span>
        </el-dialog>
        <el-dialog
          :title="'学员批量导入'"
          :visible.sync="centerDialogVisible"
          width="50%"
          center
          :before-close="doCloseload"
          class="exportdialog"
        >
          <div class="flexdcc">
            <div class="export-box">
              <h1>
                1
                <span>下载并填写导入模板</span>
              </h1>
              <div class="df">
                <span
                  @click="downloadExcel"
                  style="margin-top:20px;margin-right:20px;color:#409eff;cursor: pointer;"
                  >{{templateInfo.templateName}}</span
                >
              </div>
            </div>
            <div class="export-box">
              <h1>
                2
                <span>导入编写好的Excel文档</span>
              </h1>
              <div style="display:flex;align-items: center;padding-left: 0;background: #f3f3f3;color: red;margin:1rem 0"> 
                <img src="../../assets/icon3.png" alt="" >
                   <span style="margin:1rem .25rem"
                >请注意，学员加入到与您选择的培训区域完全匹配的班级，才算入班成功。</span
              >
              </div>
              <el-form
                :inline="true"
                ref="DialogForm"
                :model="DialogForm"
                :rules="Dialogrules"
                style="align-items: center;display: flex;"
              >
                <el-form-item label="培训区域" prop="ExcelareaId">
                  <el-cascader
                    :options="areaList"
                    clearable
                    filterable
                    :props="propsarea"
                    size="small"
                    @change="excelChange"
                    v-model="DialogForm.ExcelareaId"
                  ></el-cascader>
                </el-form-item>
                <!-- <el-form-item label="培训类型" prop="Trainingtype">
                  <tree
                    ref="tree"
                    @eventBtn="childBack2"
                    :ruleForm="DialogForm"
                    size="small"
                    @clearParams="clearParams2"
                    typeStu
                  />
                </el-form-item> -->
              </el-form>
              <div class="flexdc">
                <div class="df" style="margin:0 0 10px">
                  <el-upload
                    class="upload-demo upload-workers"
                    :action="actionUrl"
                    :on-error="handleError"
                    :on-success="handleSuccess"
                    :on-change="uploadChange"
                    :show-file-list="false"
                    :auto-upload="false"
                  >
                    <el-button class="bgc-bv" style="font-size:12px;width: 80%;"
                      >浏览</el-button
                    >
                  </el-upload>
                  <p v-if="fileName" style="margin-top:10px;margin-left:10px">
                    当前选择文件：
                    <span style="color:#f46173">{{ fileName }}</span>
                  </p>
                  <p v-else style="margin-top:10px;margin-left:10px">
                    未选择文件
                  </p>
                </div>
                <div>
                  <el-button
                    class="bgc-bv"
                    :disabled="disable"
                    size="mini"
                    style="margin-top:10px;height: 35px;"
                    @click="doExport('DialogForm')"
                    >开始导入</el-button
                  >
                </div>
                 <div style="margin-top: 10px" v-if="progressVal > 0">
              <el-progress
                :text-inside="true"
                :stroke-width="20"
                :percentage="progressVal"
              ></el-progress>
            </div>
              </div>
            </div>
            <div class="export-box">
              <h1>
                3
                <span>导入结果</span>
              </h1>
              <div class="df studentNum" style="margin:1rem 0">
                <span>学员总数量:{{ totalNum }}人;</span>
                <span>成功:{{ correctNum }}人;</span>
                <span>失败:{{ errorNum }}人;</span>
              </div>
              <div>
                <el-button
                  class="bgc-bv"
                  style="margin-top:15px;height: 35px;"
                  size="mini"
                  :disabled="errorNum == '0'"
                  @click="doExportError"
                  >导出错误数据</el-button
                >
              </div>
              <div style="margin-top:15px;color:#DD1D35;" v-if="errorNum != 0">
                有导入失败学员，请导出错误数据，将导入失败学员调整后重新上传
              </div>
            </div>
          </div>
        </el-dialog>
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import tree from "@/components/treePopup";
import { resetKeepAlive } from "@/utils/common";
import { mapGetters } from "vuex";
export default {
  name: "operate/accountList",
  components: {
    Empty,
    PageNum,
    tree,
  },
  mixins: [List],
  data() {
    return {
      userName: "",
      idcard: "",
      areatreeList: [], // 行政区划
      createMan:'', //操作人
      areaList: [],
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: false,
      },
      areaId: "", //行政区划
      ruleForm: {
        Trainingtype: "", // 培训类型
      },
      validState: "",
      validStateType: [
        {
          label: "是",
          value: true,
        },
        {
          label: "否",
          value: false,
        },
      ],
      authentication:'',
      authenticationType: [
        {
          label: "已认证",
          value: '20',
        },
        {
          label: "未认证",
          value: '10',
        },
      ],
      studyStatusList:[],
      studyState:'',
      dialogVisible: false,
      dialogTitle: "学员新增",
      stu: "add",
      Form: {
        userName: "",
        idcard: "",
        areaId: "",
        Trainingtype: "",
        userType: "",
      },
      params: {},
      params1: {},
      params2: {},
      rules: {
        userName: [
          { required: true, message: "请输入学员姓名", trigger: "blur" },
        ],
        idcard: [
          { required: true, validator: this.$validateIdCard, trigger: "blur" },
        ],
        areaId: [
          { required: true, message: "请选择行政区划", trigger: "change" },
        ],
        Trainingtype: [
          { required: true, message: "请选择培训类型", trigger: "change" },
        ],
        userType: [
          { required: true, message: "请选择学员类型", trigger: "change"}
        ]
      },
      disable:true,
      DialogForm: {
        ExcelareaId:'',
        // Trainingtype: "",
      },
      Dialogrules: {
        ExcelareaId: [
          { required: true, message: "请选择行政区划", trigger: "change" },
        ],
        // Trainingtype: [
        //   { required: true, message: "请选择培训类型", trigger: "change" },
        // ],
      },

      theClassTime: "",
      exportDate: "",
      centerDialogVisible: false,
      ExcelareaId: "",
      fileName: "",
      fileKey: "",
      totalNum: "0",
      errorNum: "0",
      correctNum: "0",
      batchId: "",
      importType: "",
      importNum: "0",
      inTheClassNum: "0",
      haveGraduationNum: "0",
      trainTypeShow: false,
      userTypeList: [],
      userTypeList1:[],
      userType: "",
      projectId: "",
      templateInfo:{},
      progressVal:0,
      fullNameList:[],
      createId:'',
    };
  },
  created() {
    console.log(this.userJson.trainTypeIdRange);
    if (this.userJson.trainTypeIdRange) {
      let trainTypeIdRange = this.userJson.trainTypeIdRange.split(",");
      trainTypeIdRange.map((el) => {
        if (el == "1"|| el == "10"|| el == "11") {
          this.trainType = '1';
        } else if (el == "2") {
          this.trainTypegs = el;
        } else if (el == "4" || el == "5" || el == "15"|| el == "16") {
          this.trainTypezyjn = '4';
        } else if (el == "6") {
          this.trainTypezyjk = el;
        } else if (el == "17") {
          this.trainTypejzpx = el;
        } else if (el == "18") {
          this.trainTypezc = el;
        } else if (el == "19") {
          this.trainTypejtys = el;
        }
      });
    }

    this.getareatree();
   
    this.getstydyStatusList();
    this.getUserTypeList();
  },
   computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
      userJson: "getUser",
    }),
  },
  methods: {
     getstydyStatusList() {
      const studyStatusList = this.$setDictionary("STUDY_STATUS", "list") || [];
      let list = [];
      for (const key in studyStatusList) {
           list.push({
          value: key,
          label: studyStatusList[key],
        });
       
      }
      this.studyStatusList = list;
    },
    handleCreate(stu, agencyStudentId = "", projectId) {
      this.dialogVisible = true;
      this.dialogTitle = "新增学员";
      this.stu = stu;
      this.agencyStudentId = agencyStudentId;
      this.projectId = projectId;
      this.getUserTypeList1();
       this.getAreaList();
      if (stu == "edit") {
        this.dialogTitle = "修改学员";
        this.getInfo(agencyStudentId);
      }
    },
    getInfo(agencyStudentId) {
      this.$post("/agency/ma-agency-student/getStudent", { agencyStudentId })
        .then((ret) => {
          this.Form.userName = ret.data.userName;
          this.Form.idcard = ret.data.idcard;
          this.Form.areaId = ret.data.areaId;
          this.Form.authentication = ret.data.authentication;
          this.Form.inTheClass = ret.data.inTheClass;
          this.Form.userType = ret.data.userType || '';
        })
        .catch((err) => {
          return;
        });
    },
    doClose(Form) {
      this.dialogVisible = false;
      this.$refs["Form"].resetFields();
      this.Form = {
        userName: "",
        idcard: "",
        areaId: "",
        authentication: "",
        inTheClass: false,
        Trainingtype: "",
        userType:'',
      };
      this.areaList = []
    },
    getSure(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.doeAjax();
        }
      });
    },
    doeAjax() {
      const parmar = {
        userName: this.Form.userName,
        idcard: this.Form.idcard,
        areaId: this.Form.areaId,
      };
      if (this.params.trainFormId) {
        parmar.trainTypeId = this.params.trainFormId;
      }
      if (this.params.levelFormId) {
        parmar.trainLevelId = this.params.levelFormId;
      }
      if (this.params.occFormId) {
        parmar.occupationId = this.params.occFormId;
      }
      if (this.params.postFormId) {
        parmar.postId = this.params.postFormId;
      }
      if (this.params.industryFormId) {
        parmar.industryId = this.params.industryFormId;
      }
      if (this.agencyStudentId) {
        parmar.agencyStudentId = this.agencyStudentId;
      }
      if (this.projectId) {
        parmar.projectId = this.projectId;
      }
      if (this.Form.userType) {
        parmar.userType = this.Form.userType;
      }
      this.$post(
        this.stu == "add"
          ? "/agency/ma-agency-student/insert"
          : "/agency/ma-agency-student/modify",
        parmar
      )
        .then((ret) => {
          if (ret.status == 0) {
            this.$message({
              message: ret.message,
              type: "success",
            });
            this.dialogVisible = false;
            // this.doClose();
            this.getData(-1);
            this.areaList = []
          }
        })
        .catch((err) => {
          return;
        });
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        validState: this.validState,
      };
      if (this.userName) {
        params.userName = this.userName;
      }
      if (this.idcard) {
        params.idcard = this.idcard;
      }
      if (this.areaId) {
        params.areaId = this.areaId;
      }
      if (this.createId) {
        params.createId = this.createId;
      }
      if (this.createMan) {
        params.createMan = this.createMan;
      }
      if (this.exportDate) {
        params.startTime = this.exportDate[0];
        params.endTime = this.exportDate[1];
      }
      if (this.theClassTime) {
        params.theClassTimeStart = this.theClassTime[0];
        params.theClassTimeEnd = this.theClassTime[1];
      }
      if (this.authentication) {
        params.authentication = this.authentication;
      }
      if (this.studyState) {
        params.studyState = this.studyState;
      }
      if (this.validState) {
        params.validState = this.validState;
      }
      if (this.params1.trainFormId) {
        params.trainTypeId = this.params1.trainFormId;
      }
      if (this.params1.levelFormId) {
        params.trainLevelId = this.params1.levelFormId;
      }
      if (this.params1.occFormId) {
        params.occupationId = this.params1.occFormId;
      }
      if (this.params1.postFormId) {
        params.postId = this.params1.postFormId;
      }
      if (this.params1.industryFormId) {
        params.industryId = this.params1.industryFormId;
      }
       if (this.theClassTimeStort) {
        params.theClassTimeStort = this.theClassTimeStort;
      }
       if (this.lessonNumPercentageStort) {
        params.lessonNumPercentageStort = this.lessonNumPercentageStort;
      }
       if (this.electiveLessonNumPercentageStort) {
        params.electiveLessonNumPercentageStort = this.electiveLessonNumPercentageStort;
      }
      if (this.userType) {
        params.userType = this.userType;
      }
      if (this.importTimeSort) {
        params.importTimeSort = this.importTimeSort;
      }
      console.log(this.importTimeSort)
      this.doFetch({
        url: "/agency/ma-agency-student/pageList",
        params,
        pageNum,
      });
      this.getCount();
    },
    handleExportList() {
      const params = {
        validState: this.validState
      };
      if (this.userName) {
        params.userName = this.userName;
      }
      if (this.idcard) {
        params.idcard = this.idcard;
      }
      if (this.areaId) {
        params.areaId = this.areaId;
      }
      if (this.createId) {
        params.createId = this.createId;
      }
      if (this.createMan) {
        params.createMan = this.createMan;
      }
      if (this.exportDate) {
        params.startTime = this.exportDate[0];
        params.endTime = this.exportDate[1];
      }
      if (this.theClassTime) {
        params.theClassTimeStart = this.theClassTime[0];
        params.theClassTimeEnd = this.theClassTime[1];
      }
      if (this.authentication) {
        params.authentication = this.authentication;
      }
      if (this.studyState) {
        params.studyState = this.studyState;
      }
      if (this.validState) {
        params.validState = this.validState;
      }
      if (this.params1.trainFormId) {
        params.trainTypeId = this.params1.trainFormId;
      }
      if (this.params1.levelFormId) {
        params.trainLevelId = this.params1.levelFormId;
      }
      if (this.params1.occFormId) {
        params.occupationId = this.params1.occFormId;
      }
      if (this.params1.postFormId) {
        params.postId = this.params1.postFormId;
      }
      if (this.params1.industryFormId) {
        params.industryId = this.params1.industryFormId;
      }
       if (this.theClassTimeStort) {
        params.theClassTimeStort = this.theClassTimeStort;
      }
       if (this.lessonNumPercentageStort) {
        params.lessonNumPercentageStort = this.lessonNumPercentageStort;
      }
       if (this.electiveLessonNumPercentageStort) {
        params.electiveLessonNumPercentageStort = this.electiveLessonNumPercentageStort;
      }
      if (this.userType) {
        params.userType = this.userType;
      }
      if (this.importTimeSort) {
        params.importTimeSort = this.importTimeSort;
      }
      this.$post("/agency/ma-agency-student/export", params)
        .then((res) => {
          if (res.status == "0") {
                let list = [];
                list.push(res.data);
                for (let item of list) {
                  console.log(item);
                  if (!this.downloadItems.includes(item.taskId)) {
                    this.$store.dispatch("pushDownloadItems", item.taskId);
                  } else {
                    this.$message.warning(
                      "[" + item.fileName + "]已经申请下载,请耐心等待"
                    );
                  }
                }
              } else {
                this.$message.error(res.message);
              }
        })
        .catch((err) => {
          return;
        });
    },
    getCount() {
      const params = {
        validState: this.validState,
      };
      if (this.userName) {
        params.userName = this.userName;
      }
      if (this.idcard) {
        params.idcard = this.idcard;
      }
      if (this.areaId) {
        params.areaId = this.areaId;
      }
      if (this.createId) {
        params.createId = this.createId;
      }
       if (this.createMan) {
        params.createMan = this.createMan;
      }
       if (this.studyState) {
        params.studyState = this.studyState;
      }
      if (this.authentication) {
        params.authentication = this.authentication;
      }
      if (this.exportDate) {
        params.startTime = this.exportDate[0];
        params.endTime = this.exportDate[1];
      }
      if (this.theClassTime) {
        params.theClassTimeStart = this.theClassTime[0];
        params.theClassTimeEnd = this.theClassTime[1];
      }
      if (this.params1.trainFormId) {
        params.trainTypeId = this.params1.trainFormId;
      }
      if (this.params1.levelFormId) {
        params.trainLevelId = this.params1.levelFormId;
      }
      if (this.params1.occFormId) {
        params.occupationId = this.params1.occFormId;
      }
      if (this.params1.postFormId) {
        params.postId = this.params1.postFormId;
      }
      if (this.params1.industryFormId) {
        params.industryId = this.params1.industryFormId;
      }
      this.$post("/agency/ma-agency-student/count", params)
        .then((ret) => {
          if (ret.status == "0") {
            this.importNum = ret.data.importNum;
            this.inTheClassNum = ret.data.inTheClassNum;
            this.haveGraduationNum = ret.data.haveGraduationNum;
          }
        })
        .catch((err) => {
          return;
        });
    },
    changeSort(row,clounm) {
       ;
       if (row.prop == "theClassTime") {
          this.theClassTimeStort = row.order;
          this.lessonNumPercentageStort = '';
          this.electiveLessonNumPercentageStort = '';
          this.importTimeSort = '';
       }
       if (row.prop == "lessonNumPercentage") {
          this.lessonNumPercentageStort = row.order;
          this.theClassTimeStort = '';
          this.electiveLessonNumPercentageStort = '';
          this.importTimeSort = '';
       }
       if (row.prop == "electiveLessonNumPercentage") {
          this.electiveLessonNumPercentageStort = row.order;
          this.lessonNumPercentageStort = '';
          this.theClassTimeStort = '';
          this.importTimeSort = '';
       }
       if (row.prop == "createTime") {
          this.importTimeSort = row.order;
          this.lessonNumPercentageStort = '';
          this.theClassTimeStort = '';
          this.electiveLessonNumPercentageStort = '';
       }
       this.getData();
    },
    getAreaList() {
      this.$post("/agency/ma-agency-student/getAreaAndTrain")
        .then((res) => {
          res.data.areaList.map(el => {
            this.setDisabled(el);
            if(el.disabled === false) {
              this.areaList.push(el)
            }
          })
        })
        .catch(() => {
          return;
        });
    },
    setDisabled(el) {
       if (this.hasEnableNode(el)) {
        el.disabled = false;
       }
       if (el.children) {
         let children = [];
        for (let i in el.children) {
          this.setDisabled(el.children[i]);
          if (!el.children[i].disabled) {
            children.push(el.children[i]);
          }
        }
        el.children = children;
       }
    },
    hasEnableNode(el) {
       if (el.disabled === false) {
         return true;
       } else if (el.children) {
         for (let i in el.children) {
           if (this.hasEnableNode(el.children[i])) {
             return true;
           }
         }
       }
       return false;
    },
    /**
     *  行政区划
     */
    getareatree() {
      this.$post("/sys/area/tree")
        .then((res) => {
          this.areatreeList = res.data || [];
        })
        .catch(() => {
          return;
        });
    },
    childBack(params) {
      this.params1 = { ...params };
    },
    childBack1(params) {
      this.params = { ...params };
    },
    clearParams() {
      this.params1 = {};
    },
    clearParams1() {
      this.params = {};
    },
    //删除
    handleDelete(agencyStudentId) {
      this.doDel({
        url: "/agency/ma-agency-student/remove",
        msg: "你确定要将该学员删除吗？",
        ps: {
          type: "post",
          data: { agencyStudentId },
        },
      });
    },
    //批量导入
    handleExport() {
      this.centerDialogVisible = true;
      this.getAreaList()
      this.getTemplate()
    },
    doCloseload() {
      this.centerDialogVisible = false;
      this.fileKey = "";
      this.fileName = "";
      this.DialogForm = {};
      this.totalNum = 0;
      this.errorNum = 0;
      this.correctNum = 0;
       this.progressVal = 0;
      this.$refs["DialogForm"].resetFields();
      this.getData();
      this.disable = true;
      this.areaList = []
    },
    downloadExcel() {
      window.open(this.templateInfo.templatePath)
      // /**
      //  * 下载模板
      //  * @param param 参数
      //  */
      // let link = document.createElement("a");
      // link.style.display = "none";
      // link.href = "/static/代理商学员导入模板.xlsx";
      // link.setAttribute("download", "代理商学员导入模板.xlsx");
      // document.body.appendChild(link);
      // link.click();
    },
    //获取模版
    getTemplate() {
      this.$post('/biz/importBiz/template',{enumType:'SHANXIPUHUIZHI_TEMPLATE'}).then(ret => {
          console.log(ret);
          this.templateInfo = {
            templateName: ret.data[0].templateName,
            templatePath: ret.data[0].templatePath
          }
      }).catch(err => {
        return
      })
    },
    // 导入学员
    uploadChange(file) {
      this.fileName = file.name;
      let size = file.size / 1024 / 1024;
      // let fileName = file.name.split(".");
      let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isXLSX = extension === "XLSX";
      const isxlsx = extension === "xlsx";
      const isXLS = extension === "XLS";
      const isxls = extension === "xls";
      if (!isXLSX && !isxlsx && !isXLS && !isxls) {
        this.$message.error("只能上传后缀是.xlsx或者.xls的文件");
        return;
      }
      if (size > 1) {
        this.$message.error("文件大小不能超过1M");
        return;
      }
      let formData = new FormData();
      formData.append("folder ", "USER");
      formData.append("file ", file.raw);
      formData.append("fileType ", extension);

      this.$Postformat("/sys/upload", formData)
        .then((result) => {
          // console.log(result);
          this.fileKey = result.data.fileKey;
          // this.fileName = result.data.fileKey;
        })
        .catch((err) => {
          return;
        });
    },
    doExport(formName) {
      if (this.fileKey == "") {
        this.$message({
          message: "请选择文件",
          type: "warning",
        });
      } else {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            if (this.fileKey) {
               let settleContent = {};
               settleContent.areaId = this.DialogForm.ExcelareaId
              const parmar = {
                enumType:'SHANXIPUHUIZHI_TEMPLATE',
                excelUrl: this.fileKey,
                param:JSON.stringify(settleContent)
              };
              this.$post(
                // "/agency/ma-agency-student/importCompanyUser",
                '/biz/importBiz/importCurrency',
                parmar,
                5000
              )
                .then((res) => {
                   if (res.status == 0) {
                      console.log(res.data);
                      this.doProgress(res.data);
                  }
                })
                .catch(() => {
                  return;
                });
            } else {
              this.$message({
                message: "请选择文件",
                type: "warning",
              });
            }
          }
        });
      }
    },
    //获取导入进度
    doProgress(batchId) {
      this.$post(
        "/biz/importBiz/import/progress",
        { batchId, enumType:'SHANXIPUHUIZHI_TEMPLATE' },
        5000
      ).then((ret) => {
        if (!ret.data.status) {
          this.progressVal = ret.data.progress;
          setTimeout(() => {
            this.doProgress(batchId);
          }, 2000);
        } else {
          if (ret.data.message) {
            this.$message({
              type: "error",
              message: '导入失败'
            });
          } else {
            this.totalNum = ret.data.totalNum;
            this.errorNum = ret.data.errorNum;
            this.correctNum = ret.data.correctNum;
            this.importType = ret.data.importType;
            this.batchId = ret.data.batchId;
            this.progressVal = ret.data.progress;
            this.$message({
              type: "success",
              message: "导入成功",
            });
          }
        }
      });
    },
    //导出错误数据
    doExportError() {
      // console.log(this.batchId);
      if (this.batchId == "" || this.fileKey == "") {
        this.$message({
          message: "还没有导入数据，请先导入文档",
          type: "warning",
        });
      } else {
        this.$post(
          // "/agency/ma-agency-student/exportAgencyStudent",
          "/biz/importBiz/error/export",
          { batchId: this.batchId, enumType:'SHANXIPUHUIZHI_TEMPLATE'  },
          5000
        ).then((res) => {
          if (res.status == 0) {
            window.open(res.data);
          }
        });
      }
    },
    
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 9) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
    getUserTypeList() {
      const userTypeList = this.$setDictionary("USERTYPE_SX", "list") || [];
      let list = [];
      for (const key in userTypeList) {
        list.push({
          value: key,
          label: userTypeList[key],
        });
      }
      this.userTypeList = list;
    },
    getUserTypeList1() {
      const userTypeList = this.$setDictionary("USERTYPE_SX", "list") || [];
      let list = [];
      for (const key in userTypeList) {
        list.push({
          value: key,
          label: userTypeList[key],
        });
      }
      this.userTypeList1 = list;
    },
    excelChange(val) {
      if(val) {
        this.disable = false
      }
    },
    // 资源提供者
    getCompSourceList(query) {
        this.$post("/agency/ma-agency-student/createAccountList", { adminName: query })
          .then(res => {
            if (res.status == 0) {
              this.fullNameList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
    },
  },
};
</script>
<style lang="less">

.table-switch {
  display: flex;
  align-items: center;
  justify-content: center;
  .el-switch__core {
    width: 40px !important;
  }
  .el-switch__core:after {
    background-color: #fff;
  }
  span {
    line-height: 20px;
    margin-left: 5px;
  }
}
.exportdialog {
  .upload-workers {
    height: 30px;
    .el-upload {
      height: 30px !important;
      width: 97px;
      border: none !important;
      .el-button {
        padding: 5px 0;
      }
    }
  }
}
</style>
<style lang="less" scoped>
.select-header {
  font-size: 14px;
  padding: 0 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #606266;
  height: 34px;
  line-height: 34px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
}
.exportdialog {
  .el-dialog__title {
    font-size: 16px;
  }
  .export-box {
    width: 100%;
    margin-bottom: 20px;
    h1 {
      padding: 0 5px 10px;
      display: flex;
      align-items: flex-end;
      font-size: 40px;
      border-bottom: 1px dashed #ccc;
      span {
        margin-left: 20px;
        font-size: 14px;
      }
    }
    > div {
      padding-left: 30px;
    }
    .el-button.is-disabled,
    .el-button.is-disabled:focus,
    .el-button.is-disabled:hover {
      color: #fff;
      background: #c3c3c3;
      border: none;
    }
  }
  .el-form-item {
    display: flex;
    align-items: center;
  }
}
</style>
